#nprogress{pointer-events:none}#nprogress .bar{background:#ffc220;height:2px;left:0;position:fixed;top:0;width:100%;z-index:1337}.nprogress-custom-parent{overflow:hidden;position:relative}.nprogress-custom-parent #nprogress .bar{position:absolute}
@font-face {
font-family: '__lato_f1f9ea';
src: url(/_next/static/media/d45d409d2fa42169-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__lato_f1f9ea';
src: url(/_next/static/media/1cf13853095a62e2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__lato_Fallback_f1f9ea';src: local("Arial");ascent-override: 99.53%;descent-override: 21.48%;line-gap-override: 0.00%;size-adjust: 99.17%
}.__className_f1f9ea {font-family: '__lato_f1f9ea', '__lato_Fallback_f1f9ea'
}.__variable_f1f9ea {--font-lato: '__lato_f1f9ea', '__lato_Fallback_f1f9ea'
}

